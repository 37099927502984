<template>
  <div class="dynamic_container w">
    <!-- 顶部导航占位符 -->
    <QbTopnavPlaceholder></QbTopnavPlaceholder>

    <!-- 标题 -->
    <QbTitle :title="title" :complement="complement"></QbTitle>
    <!-- 相关推荐 -->

    <div class="dynamic">
      <div class="dynamic_content">
        <h3 class="title">
          泉邦集团董事长马煜超受清华大学研究生院邀请，作《中小企业突围战略》主题分享
        </h3>
        <div class="dynamic_article">
          <p>
            12月1日，泉邦集团董事长马煜超受清华大学深圳研究生院培训学院邀请，于南山西丽大学城清华学思楼开讲，分享主题《中小企业突围战略》，围绕新商业时代中小企业如何找准定位、管理团队、突出重围等问题进行讲解，100余名企业家到场聆听。
          </p>
          <br />
          <br />
          <img
            class="cover"
            src="@/assets/images/home/fission_cover.png"
            alt=""
          />
          <br />
          <br />
          <p>
            马煜超老师以新时代的大趋势为背景，解析传统企业普遍存在的问题根源，针对传统企业应该如何转变思维，在不改变自身业态的情况下实现转型升级，以实际案例做出分享讲解。同时对企业的团队建设管理方面，如何捆绑核心人才、选育用留等问题提出方案方法，结合案例进行解析。
          </p>
          <br />
          <br />
          <p>
            讲座当天座无虚席，参与学习的同学们专注于课堂，马老师除了分享干货之外，还与现场学员进行了互动交流。就企业发展方向、企业管理等问题进行了深度探讨互动，大家在交流中碰撞火花，在讨论中创新思维。
          </p>
          <br />
          <br />
          <p>
            参与讲座的同学均表示本次学习受益匪浅，马老师提出的很多视角观点让人眼前一亮，对于企业发展等问题有了新的见解和想法，并表示期待日后能有机会进行系统、完整的学习。
          </p>
        </div>
      </div>

      <div class="dynamic_list">
        <div class="dynamic_list_title">相关推荐</div>
        <div class="dynamic_list">
          <div class="dynamic_item" v-for="item in article_list" :key="item.id">
            <h3 class="title">{{ item.title }}</h3>
            <div class="content">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dynamic_list',
  data() {
    return {
      title: '启动大会',
      complement: '饮水思泉  立业兴邦',
      article_list: [
        {
          id: 1,
          title: '智囊团分享 | 选择大于努力，选择泉邦，五个月收…',
          content:
            '进入泉邦第一个学习的是股东招募，以很快的速度招到第一、二批股东60人，...'
        },
        {
          id: 2,
          title: '智囊团分享 | 选择大于努力，选择泉邦，五个月收…',
          content:
            '进入泉邦第一个学习的是股东招募，以很快的速度招到第一、二批股东60人，...'
        },
        {
          id: 3,
          title: '智囊团分享 | 选择大于努力，选择泉邦，五个月收…',
          content:
            '进入泉邦第一个学习的是股东招募，以很快的速度招到第一、二批股东60人，...'
        },
        {
          id: 4,
          title: '智囊团分享 | 选择大于努力，选择泉邦，五个月收…',
          content:
            '进入泉邦第一个学习的是股东招募，以很快的速度招到第一、二批股东60人，...'
        },
        {
          id: 5,
          title: '智囊团分享 | 选择大于努力，选择泉邦，五个月收…',
          content:
            '进入泉邦第一个学习的是股东招募，以很快的速度招到第一、二批股东60人，...'
        },
        {
          id: 6,
          title: '智囊团分享 | 选择大于努力，选择泉邦，五个月收…',
          content:
            '进入泉邦第一个学习的是股东招募，以很快的速度招到第一、二批股东60人，...'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.dynamic_container {
  .dynamic {
    padding: 119px 181px 159px;
  }
  .dynamic_list_title {
    font-size: 32px;
    font-weight: 400;
    color: #010343;
    margin-bottom: 15px;
  }
}

.dynamic_content {
  font-size: 24px;
  font-weight: 400;
  color: #010343;
  line-height: 33px;
  margin-bottom: 235px;
  .title {
    font-size: 40px;
    font-weight: 500;
    line-height: 60px;
    margin-bottom: 105px;
    text-align: center;
  }
  .cover {
    width: 1559px;
    height: 820px;
  }
}

.dynamic_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .dynamic_item {
    width: 491px;
    height: 127px;
    background: #f7f9fd;
    padding: 15px 45px 0 17px;
    margin-bottom: 25px;

    .title {
      font-size: 19px;
      font-weight: 400;
      color: #010343;
      line-height: 27px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 15px;
    }
    .content {
      font-size: 19px;
      font-weight: 300;
      color: #010343;
      line-height: 27px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
}
</style>
